body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #081118;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.link-wrapper {
  display: flex;
  justify-content: right;
  align-items: center;
  height: 40px;
  width: 100vw;
}
.fullscreen-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100hw;
  width: 100vw;
  margin-top: 1em;
}
.text{
  color: white;
  margin: 0.3em;
}
.link {
  display:inline;
  justify-content: center;
  align-items: center;
  height: 3em;
  width: 3em;
  margin: 0.3em;
  margin-top: 1em;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
